import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import { Dimmer, Loader, Segment } from 'semantic-ui-react/dist/commonjs'
import { subscribeNewsletter, updateForm, reinitialize } from '../../store/subscribe'
import Sticky from 'react-stickynode'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import JoinDirectory from '../static/general/join-our-design-directory.jpg'
// import designdirectoryImage from '../static/general/ef4332e5-bf85-476a-b4ca-cdc107a2b0d4.jpg'
// import { Link } from 'react-router-dom'
import Ad from './Ad'
import FORMATS from './AdFormats'
import { Search, LinkedIn, Facebook, Twitter, Instagram, Pinterest, Youtube, TikTok } from 'ion-icon'
import { Mobile, Desktop, Tablet, DesktopTablet, MobileElse, defaultWidth } from 'ion-media'
import MostReadArticles from './MostReadArticles'
import NoImage from '../static/no-image.png'
import NotificationsBell from './NotificationsBell'

export class Sidebar extends Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = { hasMounted: false }
  }

  componentDidMount () {
    this.setState({ hasMounted: true })
  }

  handleChange (event) {
    const newState = {}
    newState[event.target.name] = event.target.value
    this.props.updateForm(newState)
  }

  validateName (name) {
    const re = /^[`a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' -]{2,45}$/i
    return name.match(re)
  }

  validateEmail (email) {
    const re = /^[^.@][^@]+@{1}[^@]+\.[^@]+[^.@]$/i
    return email.match(re)
  }

  handleSubmit (event) {
    event.preventDefault()
    if (!this.validateEmail(this.props.email)) {
      this.props.updateForm({ error: 'Please enter a valid email address' })
      return false
    }
    if (!this.validateName(this.props.firstName) || !this.validateName(this.props.lastName)) {
      this.props.updateForm({ error: 'Please enter valid first and last names' })
      return false
    }
    this.props.subscribeNewsletter({ email: this.props.email, firstName: this.props.firstName, lastName: this.props.lastName })
    return true
  }

  render () {
    const props = this.props
    const showForm = !props.hasSubmitted && !props.isSubmitting
    const submitting = props.isSubmitting
    const showThankYou = props.hasSubmitted
    return (
      <aside className='sidebar' id='sidebar'>
        <Mobile>
          <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-3' targeting={{ leaderboard: '3' }} collapseEmptyDiv className='advert-leaderboard-3' {...FORMATS.leaderboard} />
        </Mobile>
        {/* <Link to='/design-directory'><img src={JoinDirectory} alt='Join our design directory' /></Link> */}
        {this.state.hasMounted &&
          <DesktopTablet values={{ deviceWidth: defaultWidth }}>
            <div className='follow-us'>
              <span>Follow us</span>
              <nav>
                <ul>
                  <li>
                    <a href={process.env.RAZZLE_SITE_LINKEDIN} target='_blank' rel='noopener noreferrer' title='Follow House and Garden on LinkedIn'>
                      <LinkedIn width='18' height='18' />
                    </a>
                  </li>
                  <li>
                    <a href={process.env.RAZZLE_SITE_FACEBOOK} target='_blank' rel='noopener noreferrer' title='Like House and Garden on Facebook'>
                      <Facebook width='18' height='18' />
                    </a>
                  </li>
                  <li>
                    <a href={process.env.RAZZLE_SITE_INSTAGRAM} target='_blank' rel='noopener noreferrer' title='Follow House and Garden on Instagram'>
                      <Instagram width='18' height='18' />
                    </a>
                  </li>
                  <li>
                    <a href={process.env.RAZZLE_SITE_PINTEREST} target='_blank' rel='noopener noreferrer' title='Follow House and Garden on Pinterest'>
                      <Pinterest width='18' height='18' />
                    </a>
                  </li>
                  <li>
                    <a href={process.env.RAZZLE_SITE_TWITTER} target='_blank' rel='noopener noreferrer' title='Follow House and Garden on Twitter'>
                      <Twitter width='18' height='18' fill='white' />
                    </a>
                  </li>
                  <li>
                    <a href={process.env.RAZZLE_SITE_YOUTUBE} target='_blank' rel='noopener noreferrer' title='Subscribe to House and Garden on Youtube'>
                      <Youtube width='18' height='18' />
                    </a>
                  </li>
                  <li>
                    <a href={process.env.RAZZLE_SITE_TIKTOK} target='_blank' rel='noopener noreferrer' title='Subscribe to House and Garden on TikTok '>
                      <TikTok width='18' height='18' />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </DesktopTablet>}
        <div className='sidebar-search'>
          <form action='/search' method='get'>
            <input type='search' placeholder='Search House & Garden' name='q' id='q' />
            <button><Search /></button>
          </form>
        </div>
        <NotificationsBell />
        {props.children}
        {/* <div className='on-sale-now'>
          <a href='/design-directory'>
            <img src={designdirectoryImage} alt='Design Directory' />
          </a>
        </div> */}
        {this.state.hasMounted &&
          <MobileElse>
            <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='mpu-1' targeting={{ mpu: '1' }} collapseEmptyDiv className='advert-sidebar' {...FORMATS.mpu} />
          </MobileElse>}
        <div className='newsletter-subscribe'>
          <h5>Sign up to your weekly dose of inspiration</h5>
          {showForm &&
            <>
              <form name='item-form' id='item-form' onSubmit={this.handleSubmit}>
                {props.error &&
                  <small style={{ color: 'red' }}>{this.props.error ? this.props.error : 'An error occured, please try again'}</small>}
                <input type='text' name='firstName' placeholder='First Name' id='firstName' value={this.props.firstName} onChange={this.handleChange} required />
                <input type='text' name='lastName' placeholder='Last Name' id='lastName' value={this.props.lastName} onChange={this.handleChange} />
                <input type='email' name='email' placeholder='Email Address' id='email' value={this.props.email} onChange={this.handleChange} required />
                <button type='submit'>Subscribe</button>
              </form>
            </>}
          {submitting &&
            <>
              <Helmet title='Article' />
              <Segment>
                <Dimmer active>
                  <Loader indeterminate size='large'>Submitting subscription.....</Loader>
                </Dimmer>
              </Segment>
            </>}
          {showThankYou &&
            <>
              <Helmet title='Article' />
              <h3>Thank you for Subscribing!</h3>
              <p>We have sent a confirmation email to the email address that you provided. Click on the link in the email to confirm your subscription to our newsletter.</p>
            </>}
        </div>
        <div>
          <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='mpu-2' targeting={{ mpu: '2' }} collapseEmptyDiv className='advert-sidebar' {...FORMATS.mpu2} />
        </div>
        <div className='sidebar-articles'>
          <MostReadArticles section={props.section} className='side-articles' noImage={NoImage} contentKey={this.props.contentKey} />
        </div>
        <>
          {this.state.hasMounted &&
            <Desktop>
              <Sticky bottomBoundary='#sidebar' top={122}>
                <Ad
                  isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='halfpage-1' targeting={{ halfpage: '1' }} collapseEmptyDiv className='advert-sidebar'
                  desktop={[[300, 600]]} tablet={[[300, 600]]} mobile={[]}
                />
              </Sticky>
            </Desktop>}
          {this.state.hasMounted &&
            <Tablet>
              <Ad
                isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='halfpage-1' targeting={{ halfpage: '1' }} collapseEmptyDiv className='advert-sidebar'
                desktop={[[300, 600]]} tablet={[[300, 600]]} mobile={[]}
              />
            </Tablet>}
          {/* Disabled until further notice
          <Mobile>
            <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} targeting={{halfpage: '1'}} collapseEmptyDiv className='advert-sidebar'
              mobile={[]} />
          </Mobile>
          */}
        </>
      </aside>
    )
  }
}
const mapStateToProps = (state) => (Object.assign({}, state.subscribe))

const mapDispatchToProps = (dispatch) => bindActionCreators({
  subscribeNewsletter,
  updateForm,
  reinitialize
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
