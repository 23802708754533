import React, { Fragment } from 'react'
import Ad from '../../components/Ad'
import FORMATS from '../../components/AdFormats'
import ArticleCard from '../../components/ArticleCard'
import { Mobile, MobileElse } from 'ion-media'
import MagazineCover from '../../components/MagazineCover'

const Default = ({ articles, section, noImage, isConnected, moreButton }) => {
  return (
    <>
      {articles.map((article, index) => (
        <Fragment key={article.contentKey}>
          {index <= 10 && (
            <ArticleCard article={article} noImage={noImage} />
          )}
          <MobileElse>
            {(index % 18 === 0) && (index > 15) && (
              <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-vm' targeting={{ leaderboard: 'vm' }} collapseEmptyDiv className='advert-leaderboard-vm' {...FORMATS.leaderboard3} />
            )}
          </MobileElse>
          <Mobile>
            {(index === 2) && (<MagazineCover />)}
          </Mobile>
          {index > 10 && (
            <ArticleCard article={article} noImage={noImage} lazy />
          )}
          <MobileElse>
            {index === 8 && (
              <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-2' targeting={{ leaderboard: '2' }} collapseEmptyDiv className='advert-leaderboard-2' {...FORMATS.leaderboard3} />
            )}
          </MobileElse>
          {Number.isInteger((index + 5) / 4) && (
            <Mobile>
              <article className='advert'>
                <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId={'repeatMpu-' + index} targeting={{ mpu: index < 14 ? ((index + 1) / 4) : 'vm' }} collapseEmptyDiv className='align-all' adNotice {...FORMATS.mpu} />
              </article>
            </Mobile>
          )}
        </Fragment>
      ))}
      {moreButton}
    </>
  )
}

export default Default
